import React, { useContext } from "react"
import { Context } from "../Store"


// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  Container,
  Row,
  Col,
} from "reactstrap"

import Download from "./Download.js"

import "../assets/scss/_custom-font.scss"
import { isMobile } from "react-device-detect"

const images = [
  require("assets/img/komodo/header_phone.png"),
  require("assets/img/komodo/header_laptop.png")
]
const firstImageIndex = isMobile ? 0 : 1

function Header(props) {
  const [state, /*dispatch*/] = useContext(Context);
  const { strings } = state

  return (
    <>
      <header className="header-4" id="header">
        <div className="header-wrapper">
          <Navbar className="navbar-transparent" expand="lg">
            <Container>
              <div className="navbar-translate">
                <NavbarBrand
                  className="text-white"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  {strings.komodo_platform}
                </NavbarBrand>
                <button
                  aria-controls="navbarSupportedContent"
                  aria-expanded={false}
                  aria-label="Toggle navigation"
                  className="navbar-toggler"
                  data-target="#example-header-4"
                  data-toggle="collapse"
                  id="example-header-4"
                  type="button"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
              <UncontrolledCollapse
                id="example-header-4"
                navbar
                toggler="#example-header-4"
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        {strings.atomicdex}
                      </a>
                    </Col>
                    <Col className="collapse-close text-right" xs="6">
                      <button
                        aria-controls="navigation-index"
                        aria-expanded={false}
                        aria-label="Toggle navigation"
                        className="navbar-toggler"
                        data-target="#example-header-4"
                        data-toggle="collapse"
                        id="example-header-4"
                        type="button"
                      >
                        <span></span>
                        <span></span>
                      </button>
                    </Col>
                  </Row>
                </div>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
          <div className="page-header">
            <Container className="text-left">
              <Row>
                <Download
                  title={strings.header_title}
                  details={strings.header_description}
                  call_to_action={strings.header_download}
                />
                <Col className="mx-auto header-images col-xxl-7" lg="6">
                  <div className="header-bg-wrap">
                    <div className="header-gradient-ellipse ellipse-1" />
                    <div className="header-gradient-ellipse ellipse-2" />
                    <img
                      alt="..."
                      className="orbit"
                      src={require("assets/img/komodo/header_atom_orbit.svg")}
                    />
                    <img
                      alt="..."
                      className="core"
                      src={require("assets/img/komodo/header_ball.svg")}
                    />
                    <img
                      alt="..."
                      className={"header-image-first " + (firstImageIndex === 0 ? "phone-image" : "laptop-image")}
                      src={images[firstImageIndex]}
                    />
                    <img
                      alt="..."
                      className={"header-image-second " + (firstImageIndex === 1 ? "phone-image" : "laptop-image")}
                      src={images[1 - firstImageIndex]}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
