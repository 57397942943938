// reactstrap components
import {
  Col,
  Container,
  Row
} from "reactstrap"
import React, { useContext } from "react"

import { Context } from "../Store"
import { UpSlide } from "../Animation"

// Core Components

function StatsText(props) {
  return <Col className="mx-auto text-center">
    <UpSlide>
      <h2 className="stats-text">{props.number}</h2>
      <p className="stats-title-text">{props.title}</p>
    </UpSlide>
  </Col>
}
function StatusOverview(props) {
  const [state, /*dispatch*/] = useContext(Context);
  const { strings } = state

  return (
    <>
      <div className="team-2 status-overview" id="status-overview">
        <Container>

          {props.summaryData &&
            <>
              <Row>
                <StatsText title={strings.swaps_per_day} number={props.summaryData.swaps_24h} />
                <StatsText title={strings.swaps_per_month} number={props.summaryData.swaps_30d} />
                <StatsText title={strings.swaps_total} number={props.summaryData.swaps_all_time} />
              </Row>
              <Row>
                <Col className="mx-auto pt-6 text-center z-1000">
                  <UpSlide>
                    <a className="button" href="https://markets.atomicdex.io" target="_blank" rel="noopener noreferrer"><b>{strings.more_stats}</b></a>
                  </UpSlide>
                </Col>
              </Row>
            </>
          }
        </Container>
      </div>
    </>
  )
}

export default StatusOverview
