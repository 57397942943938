import React, { useContext } from "react"
import { Context } from "../Store"
import { UpSlide, LeftSlide } from "../Animation"

// reactstrap components
import { Container, Row, Col } from "reactstrap"

// Core Components

function Security() {
  const [state, /*dispatch*/] = useContext(Context);
  const { strings } = state

  return (
    <>
      <div className="bg-primary security" id="security">
        <UpSlide>
        <h2 className="mb-6 mt-lg-8">{strings.security_title}</h2>
        </UpSlide>
        <Container>
          <Row>
            <Col lg="6">
              <LeftSlide>
                <div className="description">
                  <h5 className="title">{strings.security_subtitle}</h5>
                  <p>✓ {strings.private_keys}</p>
                  <p>✓ {strings.start_trading}</p>
                  <p>✓ {strings.trading_pairs}</p>
                  <p>✓ {strings.low_fees}</p>
                </div>
              </LeftSlide>
            </Col>
            <Col className="mx-md-auto" lg="6">
              <UpSlide>
              <img
                alt="..."
                className="security-image"
                src={require("assets/img/komodo/monitor.png")}
              />
              </UpSlide>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Security
