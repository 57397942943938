// API's link, endpoints are appended to this
const API_URL = "https://stats-api.atomicdex.io/api/v1/"

// Partners Join form endpoint
const JOIN_FORM = "https://komodo.live:8181/form/"

// Google ReCaptcha v2 site key, get one from https://www.google.com/recaptcha/admin/create
const RECAPTCHA_SITE_KEY = "6LemNmcaAAAAANlW3uhKL9lARYcRLhC8Y5zS-S41"

/* 
If the nginx stuff are set, set this one empty string like "",
it will automatically use the window link like if project is landing,
window.location.origin will be returned. 


During the development, set this to http://localhost:3001 or http://localhost:3000
depending on other project's running port
*/
const OTHER_PROJECT_URL = ""

// Do not modify
module.exports = {
    OTHER_PROJECT_URL, API_URL, JOIN_FORM, RECAPTCHA_SITE_KEY,

    getOrigin: () => OTHER_PROJECT_URL !== "" ? OTHER_PROJECT_URL : window.location.origin
}
