import React, { useContext, useRef, useState } from "react"
import { Context } from "../Store"
import { LeftSlide } from "../Animation"
import { Container, Row, Col, Collapse } from "reactstrap"
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const Expandable = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className={props.className}>
      <div onClick={toggle}>
        <p><b>{props.title}</b> <i className={"fas fa-chevron-down expandable-title" + (isOpen ? " active" : "")} /></p>

      </div>
      <Collapse isOpen={isOpen}>
        <p className="expandable-content">- {props.details}</p>
      </Collapse>
    </div>
  );
}

function Build({ innerRef, coinGroupRef }) {
  const [state, /*dispatch*/] = useContext(Context);
  const { strings } = state

  const [coreTop, setCoreTop] = useState(0)

  const coreWrapRef = useRef();
  const coreRef = useRef();

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (!coinGroupRef) return;

      let coinGroupRect = coinGroupRef.current.getBoundingClientRect();
      let coreWrapRect = coreWrapRef.current.getBoundingClientRect();
      let coreRect = coreRef.current.getBoundingClientRect();

      let coreWrapTop = coreWrapRect.top - currPos.y;
      let coinGroupTop = coinGroupRect.top - currPos.y;

      let dist = coinGroupTop - coreWrapTop - coreRect.height / 2 + coinGroupRect.height / 2;

      let top = (-currPos.y - coreWrapTop) * 1.5;
      if (top < 0) top = 0;
      if (top > dist) top = dist;

      setCoreTop(top)
    },
    [setCoreTop, coinGroupRef]
  )

  return (
    <>
      <div ref={innerRef} className="build" id="build">
        <Container>
          <Row>
            <Col lg="6">
              <LeftSlide>
                <h2 className="title">{strings.build_title}</h2>
                <p className="mt-4 mb-6">{strings.build_description}</p>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <div className="build-expandable">
                    <Expandable
                      title={strings.shared_liquidity_pool}
                      details={strings.shared_liquidity_pool_description}
                    />
                    <Expandable
                      title={strings.mobile_native}
                      details={strings.mobile_native_description}
                    />
                    <Expandable
                      title={strings.liquidity_multiplier}
                      details={strings.liquidity_multiplier_description}
                    />
                    <Expandable
                      title={strings.multi_swap_support}
                      details={strings.multi_swap_support_description}
                    />
                  </div>
                </div>
                <div className="text-center text-lg-left">
                  <a
                    className="button"
                    href="https://developers.komodoplatform.com/basic-docs/atomicdex/atomicdex-api.html"
                    target="_blank" rel="noopener noreferrer"
                  >
                    {strings.read_dev_docs}
                  </a>
                  <a
                    className="button"
                    href="https://developers.komodoplatform.com/basic-docs/atomicdex/atomicdex-api.html"
                    target="_blank" rel="noopener noreferrer"
                  >
                    {strings.read_atomic_swaps}
                  </a>
                </div>
              </LeftSlide>
            </Col>
            <Col className="mx-md-auto" lg="5">
              <div className="build-bg-wrap">
                <img src={require("assets/img/komodo/build_orbit.svg")} alt="..." className="orbit" />
                <div ref={coreWrapRef} className="core-wrap">
                  <img
                    ref={coreRef}
                    src={require("assets/img/komodo/header_ball.svg")}
                    alt="..."
                    className="core"
                    style={{ top: coreTop + "px" }} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Build
