import React, { useContext } from "react"
import ReactMarkdown from 'react-markdown'
import { Context } from "../Store"
import { FadeIn } from "../Animation"

// reactstrap components
import { Container, Row, Col, Alert } from "reactstrap"
import { string } from "prop-types"

function NewsCard({ strings, className, post }) {
  return (
    <Col className={className} lg="6">
      <div className="news-card">
        <div className="img-wrap">
          <img alt="..." className="w-100" src={post.feature_image} />
        </div>
        <h5 className="mt-5">{post.title}</h5>
        <ReactMarkdown className="mt-3">{post.excerpt.replace(/\s\[.*\]/g, "").slice(0, 110) + "..."}</ReactMarkdown>
        <Row>
          <Col>
            <a href={post.url} target="_blank" rel="noopener noreferrer">
              <span>{strings.read_more}</span>
            </a>
          </Col>
          <Col className="news-date">
            <span>{new Date(post.published_at).toLocaleDateString()}</span>
          </Col>
        </Row>
      </div>
    </Col>
  )
}

function News(props) {
  const [state, /*dispatch*/] = useContext(Context);
  const { strings } = state

  return (
    <FadeIn>
      <div ref={props.innerRef} className="news mb-2" id="news">
        <Container>
          <Row>
            <Col lg="6">
              <h2 className="title">{strings.latest_news}</h2>
            </Col>
          </Row>
          <Row>
            {props.errorNews ?
              <Alert color="danger">
                {strings.news_error}: {props.errorNews.message}
              </Alert> :
              !props.news.posts || props.news.posts.length === 0 ?
                <p>{string.no_news}</p>
                :
                <>
                  {
                    props.news.posts.slice(0, 2).map((v, i) =>
                      <NewsCard
                        key={v.uuid}
                        strings={strings}
                        className="pr-lg-6"
                        post={v}
                      />
                    )
                  }
                </>}
          </Row>
        </Container>
      </div>
    </FadeIn>
  )
}

export default News
