import "assets/css/nucleo-svg.css"
import "assets/css/nucleo-icons.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "assets/scss/argon-design-system.scss?v1.0.0"

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"

import LandingPage from "views/LandingPage.js"
import React from "react"
import ReactDOM from "react-dom"
import Store from "./Store"
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-PG59K9Z'
}
 
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Store>
  <BrowserRouter>
    <Switch>
      <Route path="/" render={(props) => <LandingPage {...props} />} />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
  </Store>,
  document.getElementById("root")
)
