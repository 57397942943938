import React, { useRef, useContext, useEffect, useState } from "react"
import { Context } from "../Store"

// reactstrap components
import {
  Alert
} from "reactstrap"

// Core Components
import Navigation from "components/Navigation.js"
import Header from "components/Header.js"
import StatusOverview from "components/StatusOverview.js"
import DoingRight from "components/DoingRight.js"
import Security from "components/Security.js"
import Build from "components/Build.js"
import Compatible from "components/Compatible.js"
import TryIt from "components/TryIt.js"
import Partner from "components/Partner.js"
import Team from "components/Team.js"
import News from "components/News.js"
import Footer from "components/Footer.js"

import appConfig from "appConfig"

function LandingPage() {
  useEffect(() => {
    document.body.classList.add("landing-page")
    return () => { document.body.classList.remove("landing-page") }
  }, [])

  // Language
  const [state, /*dispatch*/] = useContext(Context);
  const { strings } = state

  // Summary
  const [error, setError] = useState(null)
  const [summaryData, setSummaryData] = useState(null)

  useEffect(() => {
    fetch(appConfig.API_URL + "atomicdexio")
      .then(res => {
        if (!res.ok) throw ''
        return res.json()
      })
      .then(
        result => {
          setSummaryData(result)
        }
      ).catch(() => {
        // fallback to static data
        setSummaryData({ "swaps_all_time": 178392, "swaps_30d": 1824, "swaps_24h": 79 })
      })
  }, [])

  // News
  const [errorNews, setErrorNews] = useState(null)
  const [news, setNews] = useState([])

  useEffect(() => {
    fetch("https://komodoplatform.com/en/blog/ghost/api/v3/content/posts/?key=81793533415f837bfc87d8b9db")
      .then(res => res.json())
      .then(
        result => {
          setNews(result)
        },
        error => {
          setErrorNews({ message: strings.failed_to_fetch_news })
        }
      )
  }, [strings])


  // Scroll to section
  const sectionRefs = {
    "features": useRef(null),
    "for_developers": useRef(null),
    "assets": useRef(null),
    "news": useRef(null),
    "partner": useRef(null),
    "team": useRef(null)
  }

  // Build -> Compatible scroll animation
  const coinGroupRef = useRef();

  const [currentType, setCurrentType] = useState(["kmd", "default"])

  return (
    <>
      <Navigation sectionRefs={sectionRefs} logo="navbar_logo.svg" type="transparent" languageSetter={true} />
      <div className="wrapper">
        <Header summaryData={summaryData} />
        {error &&
          <Alert color="danger">
            {strings.summary_error}: {error.message}
          </Alert>}
        <StatusOverview summaryData={summaryData} />
        <DoingRight innerRef={sectionRefs["features"]} />
        <Security />
        <div className="bg-primary core-animation-wrap">
          <Build
            innerRef={sectionRefs["for_developers"]}
            coinGroupRef={coinGroupRef}
          />
          <Compatible
            currentType={currentType}
            setCurrentType={setCurrentType}
            coinGroupRef={coinGroupRef}
            innerRef={sectionRefs["assets"]}
          />
        </div>
        <TryIt />
        <Partner innerRef={sectionRefs["partner"]} />
        <Team innerRef={sectionRefs["team"]} />
        <News innerRef={sectionRefs["news"]} news={news} error={errorNews} />
        <Footer sectionRefs={sectionRefs} />
      </div>
    </>
  )
}

export default LandingPage
