// reactstrap components
import {
  Col,
  Container,
  Row
} from "reactstrap"
import React, { useContext } from "react"

import { Context } from '../Store'
// import Language from "./Language.js"
import Download from "./Download"
import Language from "./Language"
// Core Components
import Navigation from "./Navigation.js"

function Footer({ sectionRefs }) {
  const [state, /*dispatch*/] = useContext(Context);
  const { strings } = state

  return (
    <>
      <footer className="footer bg-primary" id="footer">
        <Container>
          <Row className="footer-nav-row">
            <Col>
              <div className="footer-bg-wrap">
                <img className="orbit" src={require("assets/img/komodo/footer_orbit.svg")} alt="" />
              </div>
              <Navigation sectionRefs={sectionRefs} logo="footer_logo.svg" type="transparent" />
            </Col>
          </Row>
          <Row className="footer-links">
            <Col>
              <div className="column">
                <ul>
                  <li>
                    <a href="https://komodoplatform.com/discord" target="_blank" rel="noopener noreferrer">
                      <span>{strings.discord}</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/komodoplatform" target="_blank" rel="noopener noreferrer">
                      <span>{strings.twitter}</span>
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/KomodoPlatform_Official" target="_blank" rel="noopener noreferrer">
                      <span>{strings.telegram}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col className="align-right" xl="1" lg="2">
              <Download className="footer-download-buttons" />
            </Col>
          </Row>
          <Row className="mt-4 footer-copyrights flex-column flex-lg-row">
            <Col className="text-lg-left text-center mb-4  mb-lg-1">
              <span>{strings.copyright} <a href="https://komodoplatform.com/">Komodo Platform</a></span>
            </Col>

            <Col className="text-lg-right text-center mb-4 mb-lg-1">
              <Language direction="up"></Language>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}

export default Footer
