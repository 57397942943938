import React, { useContext, useState } from "react"
import { Context } from "../Store"
import { FadeIn } from "../Animation"

// reactstrap components
import { Container, Row, Col } from "reactstrap"
import PartnerModal from "./PartnerModal";

// Core Components

function Partner({ innerRef }) {
  const [state, /*dispatch*/] = useContext(Context);
  const { strings } = state

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <FadeIn>
      <div ref={innerRef} className="partner" id="partner">
        <Container>
          <Row className="align-items-center">
            <Col lg="6">
              <div className="description">
                <h2 className="title">{strings.partner_with_us}</h2>
                <p>{strings.partner_with_us_description}</p>
              </div>
            </Col>
            <Col className="mx-md-auto" lg="6">
              <div className="partner-button-div" onClick={toggle}>
                <div className="partner-button-text"><div>{strings.join}</div></div>
              </div>
            </Col>
          </Row>
        </Container>
        <PartnerModal isOpen={modal} toggle={toggle} className="partner-form" />
      </div>
    </FadeIn>
  )
}

export default Partner
