import React, { useContext } from "react"
import { Context } from "../Store"
import { FadeIn, LeftSlide, RightSlide } from "Animation";

// reactstrap components
import { Container, Row, Col, Button, Card, CardTitle, CardBody, CardText } from "reactstrap";

const teamData = [
    {
        name: "teamManagement",
        data: [
            { profilePic: require("assets/img/team/JL777.jpg"), title: "Founder/Advisor", name: "JL777", description: "", ghLink: "https://github.com/jl777", twitterLink: "", linkedinLink: "" },
            { profilePic: require("assets/img/team/Kadan-Stadelmann.jpg"), title: "CTO", name: "Kadan Stadelmann", description: "", ghLink: "https://github.com/ca333", twitterLink: "https://twitter.com/0xca333", linkedinLink: "https://linkedin.com/in/hackd" },
            { profilePic: require("assets/img/team/Artem-Pikulin.jpg"), title: "Project Lead / Core Lead Developer", name: "Artem Pikulin", description: "", ghLink: "https://github.com/artemii235", twitterLink: "", linkedinLink: "https://linkedin.com/in/artem-pikulin-473753138/" },
            { profilePic: require("assets/img/team/Tony-Lysakov.jpg"), title: "COO", name: "Tony Lysakov", description: "", ghLink: "https://github.com/tonymorony", twitterLink: "https://twitter.com/mybrainforked", linkedinLink: "https://linkedin.com/in/realtonyl/" },
            { profilePic: require("assets/img/team/Charles_Gonzalez.jpg"), title: "CSO", name: "Charles Gonzales", description: "", ghLink: "", twitterLink: "https://twitter.com/ptyx11", linkedinLink: "https://linkedin.com/in/blockchainasaservice/" },
        ]
    },
    {
        name: "teamDev",
        data: [
            { profilePic: require("assets/img/team/Sergey-Boyko.jpg"), title: "Full Stack Developer / DEX Core Engineer", name: "Sergey Boyko ", description: "", ghLink: "https://github.com/sergeyboyko0791", twitterLink: "", linkedinLink: "" },
            { profilePic: require("assets/img/team/Yurii-Khiznichenko.jpg"), title: "Full Stack Developer - Mobile Lead", name: "Yurii Khiznichenko", description: "", ghLink: "https://github.com/yurii-khi", twitterLink: "", linkedinLink: "" },
            { profilePic: require("assets/img/team/Mateus-Rodrigues-Costa.jpg"), title: "Mobile Developer", name: "Mateus Rodrigues Costa", description: "", ghLink: "https://github.com/MateusRodCosta", twitterLink: "", linkedinLink: "" },
            { profilePic: require("assets/img/team/Roman-Sztergbaum.jpg"), title: "Full Stack Developer - Desktop Lead", name: "Sztergbaum Roman", description: "", ghLink: "https://github.com/Milerius", twitterLink: "", linkedinLink: "" },
            { profilePic: require("assets/img/team/Abdramane-Sakone.jpg"), title: "Full Stack Developer", name: "Abdramane Sakone", description: "", ghLink: "https://github.com/ssakone", twitterLink: "", linkedinLink: "" },
            { profilePic: require("assets/img/team/syl.jpg"), title: "Full Stack Developer", name: "Syl", description: "", ghLink: "https://github.com/SylEze", twitterLink: "", linkedinLink: "" },
        ]
    },
    {
        name: "teamQA",
        data: [
            { profilePic: require("assets/img/team/Thor-Mennet.jpg"), title: "DevOps/QA Engineer - DEX Expert", name: "Thor Mennet", description: "", ghLink: "https://github.com/smk762", twitterLink: "", linkedinLink: "" },
            { profilePic: require("assets/img/team/Igor-Govyashov.jpg"), title: "DevOps Engineer - QA Expert", name: "Igor Govyashov", description: "", ghLink: "https://github.com/SirSevenG", twitterLink: "", linkedinLink: "" },
            { profilePic: require("assets/img/team/Guru-Charan-Gupta-V-B.jpg"), title: "DevOps Engineer - DEX Expert", name: "Guru Charan Gupta V B", description: "", ghLink: "https://github.com/gcharang", twitterLink: "https://twitter.com/gcharang", linkedinLink: "https://linkedin.com/in/gcharang/" },
        ]
    }
]

// Core Components

function SocialButton({ color, href, id, faClassName }) {
    return (
        <>
            <Button
                className="rounded-circle"
                color={color}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
            >
                <i className={faClassName}></i>
            </Button>
        </>
    )
}

function ProfileCard({ profilePic, title, name, description, ghLink, twitterLink, linkedinLink }) {
    let SocialButtons = []
    let ghButton, twitterButton, linkedinButton
    if (ghLink) {
        ghButton = <SocialButton
            faClassName="fab fa-github"
            color="github"
            href={ghLink}
            key="ghButton"
        >
        </SocialButton>
        SocialButtons.push(ghButton)
    }
    if (twitterLink) {
        twitterButton = <SocialButton
            faClassName="fab fa-twitter"
            color="twitter"
            href={twitterLink}
            key="twitterButton"
        >
        </SocialButton>
        SocialButtons.push(twitterButton)
    }
    if (linkedinLink) {
        linkedinButton = <SocialButton
            faClassName="fab fa-linkedin"
            color="linkedin"
            href={linkedinLink}
            key="linkedinButton"
        >
        </SocialButton>
        SocialButtons.push(linkedinButton)
    }
    return (
        <>
            <Card className="card-profile" data-image="img-rounded">
                <div className="card-avatar">
                    <img
                        alt="..."
                        className="img"
                        src={profilePic}
                    ></img>
                </div>
                <h6 className="card-category text-gray mt-4">{title}</h6>
                <CardBody className="pt-0">
                    <CardTitle tag="h4">{name}</CardTitle>
                    {/* <CardText>
                        {description}
                    </CardText> */}
                    <div className="text-center">
                        {SocialButtons}
                    </div>
                </CardBody>
            </Card>
        </>
    );
}

function TeamRow({ data }) {
    return (
        <>
            <Row className="teamRow">
                {
                    data.map(({ profilePic, title, name, description, ghLink, twitterLink, linkedinLink }, i) => <Col lg="3" md="6" key={i}>
                        <ProfileCard profilePic={profilePic} name={name} title={title} description={description} ghLink={ghLink} twitterLink={twitterLink} linkedinLink={linkedinLink} />
                    </Col>)
                }
            </Row>
        </>
    )
}

function Team({ innerRef }) {
    const [state] = useContext(Context);
    const { strings } = state;

    return (
        <>
            <div ref={innerRef} className="team" id="team">
                <Container>
                    <FadeIn>
                        <Row>
                            <Col className="mx-auto text-center mb-5" md="8">
                                <h2 className="title">The Team</h2>
                            </Col>
                        </Row>
                    </FadeIn>
                    {
                        teamData.map(({ name, data }, i) => <div key={i}>
                            <FadeIn>
                                <Row>
                                    <Col className="mx-auto text-center mb-5 mt-5" md="8">
                                        <h4 className="title">{strings[name]}</h4>
                                    </Col>
                                </Row>
                            </FadeIn>
                            {(i % 2 == 0) ? <LeftSlide><TeamRow data={data} /></LeftSlide> : <RightSlide><TeamRow data={data} /></RightSlide>}
                        </div>)
                    }
                </Container>
            </div>
        </>
    );
}

export default Team;
