import React, { useContext } from "react"
import { Context } from "../Store"
import { UpSlide, RightSlide, LeftSlide } from "../Animation"

// reactstrap components
import { Container, Row, Col } from "reactstrap"



function InfoImage({ src }) {
  return (
    <div className='info-image'>
      <img alt="..." src={src} />
    </div>
  )
}


function Info({ title, description }) {
  return (
    <div className="description">
      <h5 className="title">{title}</h5>
      <p>{description}</p>
    </div>
  )
}

function DoingRight({ innerRef }) {
  const [state, /*dispatch*/] = useContext(Context);
  const { strings } = state

  return (
    <>
      <div ref={innerRef} className="mt-6 doing-right" id="doing-right">
        <UpSlide>
        <h2 className="mt-6 mb-9">{strings.doing_right_title}</h2>
        </UpSlide>
        <Container>
          <Row >
            <Col className="mx-md-auto" lg="6">
              <LeftSlide>
                <InfoImage src={require("assets/img/komodo/phone_scroll_1.png")} />
              </LeftSlide>
            </Col>
            <Col lg="6">
              <RightSlide>
                <Info title={strings.using_p2p_orderbooks} description={strings.using_p2p_orderbooks_description} />
              </RightSlide>
            </Col>
          </Row>
          <Row className="d-none d-lg-flex">
            <Col lg="6">
              <LeftSlide>
                <Info title={strings.cross_chain_support} description={strings.cross_chain_support_description} />
              </LeftSlide>
            </Col>
            <Col className="mx-md-auto" lg="6">
              <RightSlide>
                <InfoImage src={require("assets/img/komodo/phone_scroll_2.png")} />
              </RightSlide>
            </Col>
          </Row>
          <Row className="d-flex d-lg-none">
            <Col className="mx-md-auto" lg="6">
              <LeftSlide>
                <InfoImage src={require("assets/img/komodo/phone_scroll_2.png")} />
              </LeftSlide>
            </Col>
            <Col lg="6">
              <RightSlide>
                <Info title={strings.cross_chain_support} description={strings.cross_chain_support_description} />
              </RightSlide>
            </Col>
          </Row>
          <Row >
            <Col className="mx-md-auto" lg="6">
              <LeftSlide>
                <InfoImage src={require("assets/img/komodo/phone_scroll_3.png")} />
              </LeftSlide>
            </Col>
            <Col lg="6">
              <RightSlide>
              <Info title={strings.secure_as_possible} description={strings.secure_as_possible_description} />
              </RightSlide>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default DoingRight
