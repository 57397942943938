import React, { useContext } from "react"
import { Context } from "../Store"
import { FadeIn } from "../Animation"

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap"

import Download from "./Download.js"

import "../assets/scss/_custom-font.scss"

function TryIt(props) {
  const [state, /*dispatch*/] = useContext(Context);
  const { strings } = state

  return <>
    <div className="try-it" id="try-it">
      <Container className="text-left">
        <Row className="try-it-row">
          <Col className="mx-md-auto align-center" lg="6">
            <FadeIn>
              <div className="bg-wrap">
                <div className="try-it-gradient-ellipse ellipse-1" />
                <div className="try-it-gradient-ellipse ellipse-2" />
                <img
                    alt="..."
                    className="orbit"
                    src={require("assets/img/komodo/tryit_atom_orbit.svg")}
                />
              </div>
              <img
                  alt="..."
                  className="try-it-phone"
                  src={require("assets/img/komodo/download_phone.png")}
              />
            </FadeIn>
          </Col>
          <Download
            className = "try-it-description"
            title={strings.lets_try}
            details=""
            call_to_action={strings.lets_try_download}
            />
        </Row>
      </Container>
    </div>
  </>
}

export default TryIt
