import React, { useContext, useState } from "react"
import { Context } from "../Store"

// reactstrap components
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Spinner } from 'reactstrap'
import ReCAPTCHA from "react-google-recaptcha";
import ModalFooter from "reactstrap/lib/ModalFooter";
import appConfig from "appConfig";

// Core Components

function PartnerModal({ className, isOpen, toggle }) {
  const [state, /*dispatch*/] = useContext(Context);
  const { strings } = state

  let [name, setName] = useState("")
  let [email, setEmail] = useState("")
  let [website, setWebsite] = useState("")
  let [message, setMessage] = useState("")

  let [isSuccess, setIsSuccess] = useState(false)
  let [isInProgress, setIsInProgress] = useState(false)
  let [errorMessage, setErrorMessage] = useState("");
  let [reCaptchaValue, setReCaptchaValue] = useState();


  const validName = name !== ""
  const validEmail = email !== "" && email.indexOf("@") !== -1 && email[0] !== "@" && email[email.length - 1] !== "@"
  const validWebsite = true
  const validMessage = message !== ""
  const isValid = validName && validEmail && validWebsite && validMessage && reCaptchaValue

  const toggleModal = () => {
    toggle();
    resetFormStatus();
  }

  const resetFormStatus = () => {
    setIsInProgress(false);
    setIsSuccess(false);
    setErrorMessage("");
    setReCaptchaValue(null);
  }

  const submitForm = async (e) => {
    e.preventDefault();

    if (isInProgress) return;
    resetFormStatus();
    setIsInProgress(true);

    let response;
    try {
      response = await fetch(appConfig.JOIN_FORM, {
        method: "POST",
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify({ name, email, website, message, reCaptchaValue })
      });
    } catch (e) {
      setIsInProgress(false);
      setErrorMessage(e);
      return;
    }

    setIsInProgress(false);

    if (response?.ok) {
      setIsSuccess(true);
    } else {
      let errorMessage = response?.error?.toString()?.length ? response.error : strings.form_try_again;
      setErrorMessage(errorMessage);
    }
  }

  const form = <div className={'form-wrap' + (isInProgress ? ' in-progress' : '')}>
    <ModalBody>
      <Form>
        <FormGroup>
          <Label for="name">{strings.your_name}</Label>
          <Input value={name} invalid={!validName} type="text" name="name" id="name" placeholder={strings.your_name} onChange={e => setName(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label for="email">{strings.your_email}</Label>
          <Input value={email} invalid={!validEmail} type="email" name="email" id="email" placeholder={strings.your_email} onChange={e => setEmail(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label for="website">{strings.project_website}</Label>
          <Input value={website} invalid={!validWebsite} type="text" name="website" id="website" placeholder={strings.project_website} onChange={e => setWebsite(e.target.value)} />
        </FormGroup>
        <FormGroup>
          <Label for="message">{strings.your_message}</Label>
          <Input value={message} invalid={!validMessage} type="textarea" name="message" id="message" placeholder={strings.your_message} onChange={e => setMessage(e.target.value)} />
        </FormGroup>
        <FormGroup className="mt-4 mb-4 text-center">
          <ReCAPTCHA
            sitekey={appConfig.RECAPTCHA_SITE_KEY}
            onChange={setReCaptchaValue}
          />
        </FormGroup>
        <Button disabled={!isValid} color="secondary" onClick={submitForm}>{strings.send}</Button>
        <Button color="secondary" onClick={toggleModal}>{strings.cancel}</Button>
      </Form>
    </ModalBody>
    <ModalFooter>
      <small>{strings.click_consent}</small>
    </ModalFooter>

    <div className="spinner-wrap">
      <Spinner color="info" className="spinner" />
    </div>
  </div>;

  const errorScreen = <ModalBody>
    <div className="form-error">
      <div className="title">
        {strings.form_error}
      </div>
      <div className="message">
        {errorMessage.toString()}
      </div>
    </div>
    <Button color="primary" onClick={resetFormStatus}>{strings.resend}</Button>
    <Button color="secondary" onClick={toggleModal}>{strings.cancel}</Button>
  </ModalBody>;

  const succesScreen = <ModalBody>
    <div className="form-success">
      <div className="title">
        <img src={require("assets/img/icon_success.png")} alt="success" />
        {strings.form_success}
      </div>
    </div>
    <Button color="secondary" onClick={toggleModal}>{strings.close}</Button>
  </ModalBody>

  let modalContent = form;
  if (isSuccess) {
    modalContent = succesScreen;
  } else if (errorMessage?.toString().length) {
    modalContent = errorScreen;
  }

  return (
    <Modal className={className} isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>{strings.partner_with_us}</ModalHeader>
      {modalContent}
    </Modal>
  )
}

export default PartnerModal
