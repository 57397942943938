import React, { useContext, useState } from "react"
import { Context } from "../Store"
import { LeftSlide } from "../Animation"
import { Container, Row, Col } from "reactstrap"
import Button from "reactstrap/lib/Button"
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import Tooltip from "./Tooltip";


const coinData = {
  default: {
    kmd: {
      name: "Komodo",
      img: "kmd",
    },
    mcl: {
      name: "MarmaraChain",
      img: "mcl",
    },
    eth: {
      name: "Ethereum",
      img: "eth",
    },
    btc: {
      name: "Bitcoin",
      img: "btc",
    },
    bat: {
      name: "Basic Attention Token",
      img: "bat",
    },
    busd: {
      name: "Binance USD",
      img: "busd",
    },
    dgb: {
      name: "DigiByte",
      img: "dgb",
    },
    qtum: {
      name: "Qtum",
      img: "qtum",
    },
    rvn: {
      name: "Ravencoin",
      img: "rvn",
    },
    usdc: {
      name: "USD Coin",
      img: "usdc",
    },
    vrsc: {
      name: "Verus Coin",
      img: "vrsc",
    },
  },
  eth: {
    eth: {
      name: "Ethereum",
      img: "eth",
    },
    bat: {
      name: "Basic Attention Token",
      img: "bat",
    },
    busd: {
      name: "Binance USD",
      img: "busd",
    },
    dai: {
      name: "Dai",
      img: "dai",
    },
    kcs: {
      name: "KuCoin Shares",
      img: "kcs",
    },
    link: {
      name: "Chainlink",
      img: "link",
    },
    paxos: {
      name: "Paxos",
      img: "paxos",
    },
    tusd: {
      name: "TrueUSD",
      img: "tusd",
    },
    uni: {
      name: "Uniswap",
      img: "uni",
    },
    usdc: {
      name: "USD Coin",
      img: "usdc",
    },
    yfi: {
      name: "Yearn Finance",
      img: "yfi",
    },
  },
  qtum: {
    qtum: {
      name: "Qtum",
      img: "qtum",
    },
    qi: {
      name: "QiSwap",
      img: "qi",
    },
    hlc: {
      name: "HalalChain",
      img: "hlc",
    },
    hpy: {
      name: "Hyper Pay",
      img: "hpy",
    },
    tsl: {
      name: "Energo",
      img: "tsl",
    },
    zat: {
      name: "Zatgo",
      img: "zat",
    },
    put: {
      name: "PutinCoin",
      img: "put",
    },
    med: {
      name: "MediBloc",
      img: "med",
    },
    oc: {
      name: "OceanChain",
      img: "oc",
    },
    lstr: {
      name: "Luna Stars",
      img: "lstr",
    },
    qbt: {
      name: "Qbao",
      img: "qbt",
    },
    qc: {
      name: "Qcash",
      img: "qc",
    },
    spc: {
      name: "SpaceChain",
      img: "spc",
    },
    ink: {
      name: "Ink",
      img: "ink",
    },
  },
  bnb: {
    bnb: {
      name: "Binance Coin",
      img: "bnb"
    },
    btc: {
      name: "Bitcoin-BEP20",
      img: "btc"
    },
    ada: {
      name: "Cardano-BEP20",
      img: "ada"
    },
    atom: {
      name: "Cosmos-BEP20",
      img: "atom"
    },
    avax: {
      name: "Avalanche-BEP20",
      img: "avax"
    },
    btt: {
      name: "BitTorrent-BEP20",
      img: "btt"
    },
    doge: {
      name: "Dogecoin-BEP20",
      img: "doge"
    },
    dot: {
      name: "Polkadot-BEP20",
      img: "dot"
    },
    eos: {
      name: "EOS-BEP20",
      img: "eos"
    },
    fil: {
      name: "Filecoin-BEP20",
      img: "fil"
    },
    iota: {
      name: "IOTA-BEP20",
      img: "iota"
    },

  },
  utxo: {
    btc: {
      name: "Bitcoin",
      img: "btc",
    },
    dgb: {
      name: "DigiByte",
      img: "dgb",
    },
    rvn: {
      name: "Ravencoin",
      img: "rvn",
    },
    vrsc: {
      name: "VerusCoin",
      img: "vrsc",
    },
    dash: {
      name: "Dash",
      img: "dash",
    },
    doge: {
      name: "Dogecoin",
      img: "doge",
    },
    emc2: {
      name: "Einsteinium",
      img: "emc2",
    },
    firo: {
      name: "Firo",
      img: "firo",
    },
    ltc: {
      name: "Litecoin",
      img: "ltc",
    },
    bch: {
      name: "Bitcoin Cash",
      img: "bch",
    },
    nav: {
      name: "Navcoin",
      img: "nav",
    },
  },
};

function Compatible({ currentType, setCurrentType, coinGroupRef, progress, innerRef }) {
  const [state, /*dispatch*/] = useContext(Context);
  const { strings } = state

  const iconCount = 11
  const firstBreak = 3
  const secondBreak = 6
  const getButtonColor = (type) => type === currentType[0] ? "info" : "white"

  const [coinScale, setCoinScale] = useState(0.2);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (!coinGroupRef) return;

      let coinGroupRect = coinGroupRef.current.getBoundingClientRect();
      let coinGroupScreenTop = coinGroupRect.top;
      let scale = (window.innerHeight - coinGroupScreenTop) * 3 / window.innerHeight;

      if (scale < 0.2) scale = 0.2;
      if (scale > 1) scale = 1;

      setCoinScale(scale);
    },
    [setCoinScale, coinGroupRef]
  )

  return (
    <>
      <div ref={innerRef} className="compatible pt-8 pb-8" id="compatible">
        <Container>
          <Row className="align-items-center">
            <Col lg="5">
              <LeftSlide>
                <h2 className="title">{strings.compatible_title}</h2>
                <p className="mt-4 mb-5">{strings.compatible_description}</p>

                <Button
                  color={getButtonColor("kmd")}
                  onClick={() => {
                    setCurrentType(["kmd", "default"]);
                  }}
                  target="_blank"
                  className="mb-2"
                >
                  {strings.featured}
                </Button>
                <Button
                  color={getButtonColor("eth")}
                  onClick={() => {
                    setCurrentType(["eth", "eth"]);
                  }}
                  target="_blank"
                  className="mb-2"
                >
                  {strings.eth_erc}
                </Button>
                <Button
                  color={getButtonColor("qtum")}
                  onClick={() => {
                    setCurrentType(["qtum", "qtum"]);
                  }}
                  target="_blank"
                  className="mb-2"
                >
                  {strings.qtum_qrc}
                </Button>
                <Button
                  color={getButtonColor("bnb")}
                  onClick={() => {
                    setCurrentType(["bnb", "bnb"]);
                  }}
                  target="_blank"
                  className="mb-2"
                >
                  {strings.bnb_bep}
                </Button>
                <Button
                  color={getButtonColor("utxo")}
                  onClick={() => {
                    setCurrentType(["utxo", "utxo"]);
                  }}
                  target="_blank"
                  className="mb-2"
                >
                  {strings.utxo_coins}
                </Button>
              </LeftSlide>
            </Col>
            <Col className="mx-md-auto" lg="7">
              <div className="coin-group" ref={coinGroupRef}>
                {Object.keys(coinData[currentType[1]])
                  .slice(0, iconCount)
                  .map((v, i) => (                 
                      <span key={v} className="coin-span">
                        <Tooltip
                          content={coinData[currentType[1]][v].name}
                          direction="top"
                          delay="0"
                        >
                          <img
                            style={{
                              transform: `scale(${coinScale * 1.3})`,
                            }}
                            className="coin-icon"
                            alt={coinData[currentType[1]][v].name}
                            src={require("assets/img/coins/" +
                              currentType[1] +
                              "/" +
                              v +
                              ".png")}
                          />
                        </Tooltip>
                        {(i === firstBreak || i === secondBreak) && <br />}
                      </span>              
                  ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Compatible
